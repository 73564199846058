.listing {
  .grid {
    /*display: grid;

    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    margin-bottom: 1rem;
*/

    .product {
      display: flex;
      flex-direction: column;
      > * {
        flex-grow: 1;
      }
    }


    .category-img {
      height: 10rem;
      width: 100%;
      object-fit: contain;
    }
  }


  .product-group {
    display: flex;
    flex-direction: column;
    > * {
      flex-grow: 1;
    }
    .listing-image-wrapper {
      position: relative;
      padding-bottom: 2rem;
    }
    .image-grid {
      padding: .5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      display: flex;
      gap: .5rem;

      flex-wrap: wrap-reverse;


      img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        border: solid 1px lightgray;
        background: white;

      }
    }
  }
}