@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');

$font-size-base: 1rem;
$primary: #ba2a2f;
$secondary: #54595F;

$card-cap-bg: #000;
$card-cap-color: #FFF;
$headings-font-family: 'PT Sans Narrow', sans-serif;

$card-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$h3-font-size: $font-size-base * 1.2;
$border-radius: 4px;
$link-decoration: none;
$small-font-size: .7em;

$breadcrumb-active-color: #CCC;
//$nav-link-color: white;


$carousel-control-color: $primary;

$breadcrumb-divider: quote(">");


@import "~bootstrap/scss/bootstrap";

//@import '~mdb-ui-kit/css/mdb.min.css';


@import "_partials/listing";

small {
  font-style: italic;
}

$backdrop: #00000099;

.btn-toggle:not(.collapsed) {

  .material-icons {
    transform: rotate(90deg);
  }
}

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $card-box-shadow;
}

.pagination {


  display: grid;
  grid-gap: .5rem;
  grid-template-columns: repeat(auto-fit, 3rem);

  > * {
    margin: 0;
    display: flex;

    .page-link {
      flex-grow: 1;
      margin: 0 !important;
    }
  }
}

.imprints {
  .btn-group {

  }
}

.jb-link-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $backdrop;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: white;

  transition: .3s ease;

  &:hover {
    opacity: 1;
  }
}

.loading {
  opacity: 0.6;
  pointer-events: none;
}

#aside {
  .active {
    > div:first-child {
      border-bottom: 2px solid $primary;
      background: transparentize($primary, 0.75);
    }
  }
}

/* ============ desktop view .end// ============ */
/* ============ mobile view ============ */
@media(max-width: 991px) {
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}

.kp-customizer-current-design {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0;
  max-width: 360px;
  min-height: 120px;

  >div {
    padding: 5px;
  }

  img {
    width: 100%;
  }
}
img {
  font-size: 0;
}